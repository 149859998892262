@use './typography' as *;
@use './variables' as *;


@mixin tablet {
    @media (min-width: $breakpoint-tablet) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $breakpoint-desktop) {
        @content;
    }
}