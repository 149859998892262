@use "../../Styles/typography" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/globals" as *;
@use "../../Styles/variables" as *;

.projects {
    padding: 1rem;
    background-color: #FAF9F6;
    display: flex;
    flex-direction: column;

    @include desktop{
        padding: 2rem;
    }

    &__container{
        @include desktop{
        display: flex;
        flex-direction: row;
    }
    }

    

    &__header{
        font-size: 2rem;
        font-weight: 800;
        color: black;
        margin-bottom: 1rem;

        @include desktop{
            font-size: 2.5rem;
        }
    }

    &__card{
        @include desktop{
            margin: 1rem;
        }
    }

    
}
