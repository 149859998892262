@font-face {
    font-family: 'manrope';
    src: url("../Assets/Fonts/static/Manrope-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: 'manrope';
    src: url("../Assets/Fonts/static/Manrope-Bold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: 'manrope';
    src: url("../Assets/Fonts/static/Manrope-SemiBold.ttf") format("truetype");
    font-weight: 600;
}