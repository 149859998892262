@use '../../Styles/typography' as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/globals" as *;
@use "../../Styles/variables" as *;


.hero{
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem;
    @include tablet{
        flex-direction: row;
        align-items: center;
    }
    @include desktop{
        padding: 2rem;
        height: 50rem;
    }


    &__container{

        &-content{
            font-family: 'manrope';
            display: flex;
            flex-direction: column;
        }

        &-image{
            display: flex;
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }

    &__header{
        font-size: 1.75rem;
        font-family: 'manrope';
        margin-top: 1rem;
        font-weight: 900;
        @include tablet{
            font-size: 2.5rem;
        }

        @include desktop{
            font-size: 4.5rem;
        }
        &-span{
            color: rgba($aqua, $alpha: 1.0);
            font-weight: 900;
        }
    }

    &__subheader{
        font-size: 1rem;
        font-family: 'manrope';
        font-weight: 700;
        @include tablet{
            font-size: 1.5rem;
        }
        @include desktop{
            font-size: 2rem;
        }
    }

    &__image{
        width: 100%;
        @include tablet{
            width: 25rem;
        }

        @include desktop{
            width: 40rem;
        }
    }
}