@use "../../Styles/variables" as *;

.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin: auto;
    background-color: #FAF9F6;
    padding: 1rem;

    &__container-social{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__subheader{
        font-family: 'manrope';
        font-size: 1rem;
    }

    &__social{
        width: 2.5rem;
        height: 2.5rem;
        margin: 1rem;

        &-git{
            width: 3.25rem;
            height: 3.25rem;
            margin: .65rem;
        }
    }
}