@use '../../Styles/globals' as *;
@use '../../Styles/mixins' as *;
@use '../../Styles/typography' as *;
@use '../../Styles/variables' as *;

.aboutpage{
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @include desktop{
        flex-direction: row;
        padding: 2rem;
    }

    &__container-about{
        padding: 1rem;
        
        @include desktop{
            width: 70%;
        }
    }

    &__about{

        &-header{
            font-family: 'manrope';
            font-weight: 900;
        }

        &-content{
            font-family: 'manrope';
            font-size: 1.25rem;
        }
    }

    &__container-button{
        display: flex;
        justify-content: center;

        @include desktop{
            flex-grow: 1;
        }
        
        
    }

    &__button{
        background-color: $aqua;
        border-radius: 8px;
        border: none;
        padding: 1rem 2rem;
        font-size: 1.5rem;
        font-weight: 800;
        margin: 1rem 0;
    }

}