@use "../../Styles/typography" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/globals" as *;
@use "../../Styles/variables" as *;

.languages{
    padding: 1rem;

    @include desktop{
        padding: 2rem;
    }

    &__container-images{
        @include tablet{
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
        }
    }

    &__header{
        font-weight: 800;
        font-size: 1.4rem;

        @include desktop{
            font-size: 2.5rem;
        }
    }

    &__image{
        height: 2.5rem;
        margin: 1rem;

        @include desktop{
            height: 7rem;
        }
    }

    &__container-images-section{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        margin: auto;
    }
}
.bulma{
    height: 1rem;

    @include desktop{
        height: 2rem;
    }
}

.mysql{
    margin: unset;
}

.express, .knex, .firebase{
    margin: .25rem;
}