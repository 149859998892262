@use "../../Styles/typography" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/globals" as *;
@use "../../Styles/variables" as *;

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;

    @include desktop{
        padding: 2rem;
    }

    &__logo {
        height: 2.5rem;

        @include desktop{
            height: 3.5rem;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        @include tablet{
            flex-direction: row;
        }

        &-icon{
            display: flex;
            justify-content: flex-end;
        }

        &-links {
            display: flex;
            background-color: rgb(247, 242, 242);
            padding: 1rem;
            width: fit-content;
            position: absolute;
            width: 100%;
            top: 60px;
            right: 0;
            @include tablet {
                display: flex;
                position: unset;
                background-color: white;

            }
        }
    }

    &__links{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-left: 0;
        @include tablet{
            flex-direction: row;
            
        }
    }

    &__link {
        text-decoration: none;
        list-style: none;
        margin: .25rem 0rem;
        font-family: 'manrope';
        font-weight: 800;
        color: black;
        @include tablet{
            margin: 0 1rem;
        }

        @include desktop{
            font-size: 1.5rem;
        }
    }

    &__mobile-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-decoration: none;
        list-style: none;

        &-icon {
            height: 2.5rem;
            max-width: 2.5rem;
            @include tablet{
                display: none;
            }
        }
    }
}

.active{
    opacity: 1;
    transition: 0.3s;
    @include tablet{
        opacity: unset;
        transition: unset;
    }
}

.inactive{
    opacity: 0;
    display: none;
    transition: 0.3s;
    @include tablet{
        opacity: unset;
        display: unset;
        transition: unset;
    }
}