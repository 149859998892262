@use "../../Styles/typography" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/globals" as *;
@use "../../Styles/variables" as *;


.ukg{
    border: 1px solid black;
    border-radius: 8px;
    box-shadow: 0 19px 38px rgba(black, 0.3), 0 15px 12px rgba(black, 0.2);
    margin-bottom: 3rem;
    max-width: 27.3125rem;

    @include tablet{
        height: 20rem;
        overflow: hidden;
        margin-bottom: 3rem;
        max-width: unset;
    }

    @include desktop{
        margin: 1rem;
        height: 50rem;
        width: 198rem;
        max-width: 198rem;
        max-height: 50rem;
    }
    
    &__container{
        color: black;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        
        @include tablet{
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        @include desktop{
            flex-direction: column;
            overflow: hidden;
        }
    }

    &__carousel{
        aspect-ratio: 16 / 9;
        max-height: 11.25rem;
        object-fit: cover;
        width: 100%;

        @include tablet{
            aspect-ratio: unset;
            width: 50%;
            margin: auto;
            overflow: hidden;
            max-height: unset;
        }

        @include desktop{
            aspect-ratio: auto;
            width: 100%;
            height: 16rem;
        }

        &-image{
            width: 100%;
            border-radius: 8px 8px 0 0;
            max-height: 11.25rem;
            object-fit: fill;

            @include tablet{
                height: 20rem;
                width: unset;
                border-radius: 8px 0 0 8px;
                object-fit: cover;
                max-height: unset;
            }

            @include desktop{
                max-height: 100%;
                max-width: 100%;
                height:16rem;
                border-radius: 8px 8px 0 0;
                object-fit: cover;
            }
        }
    }

    &__content{
        padding: 1rem;

        @include tablet{
            width: 50%;
        }

        @include desktop{
            width: unset;
        }

        &-title{
            font-size: 1.3rem;
            font-weight: 900;

            @include desktop{
                font-size: 2rem;
                font-weight: 900;
            }
        }

        &-description{
            font-size: 1rem;

            @include desktop{
                font-size: 1.5rem;
            }
        }

        &-languages{

            @include desktop{
                margin: 1rem 0;
            }

            &-span{
                font-weight: 800;
            }
        }
        
    }
}